import React, { Fragment, useState } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import { Btn, H5, LI, P, UL } from "../../../../AbstractElements";
import {
  Activity,
  BarChart2,
  Briefcase,
  Calendar,
  CheckSquare,
  DollarSign,
  MapPin,
  Phone,
  User,
} from "react-feather";
import moment from "moment";

const AboutEmployee = ({ colClass, selectedEmployee }) => {
  const [isShow, setIsShow] = useState(true);
  const toggleClick = () => {
    setIsShow(!isShow);
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  return (
    <Fragment>
      <div className={colClass}>
        <Card data-intro="This is your Your detail" className="step4">
          <CardHeader>
            <H5 attrH5={{ className: "p-0" }}>
              <Btn
                attrBtn={{
                  className: "btn btn-link ps-0",
                  color: "transperant",
                  onClick: toggleClick,
                }}
              >
                Employee Details
              </Btn>
            </H5>
          </CardHeader>
          <div
            className={`collapse ${isShow ? "show" : ""}`}
            id="collapseicon2"
            aria-labelledby="collapseicon2"
            data-parent="#accordion"
          >
            <CardBody className="post-about">
              <UL>
                <LI>
                  <div className="icon">
                    <User />
                  </div>{" "}
                  <div>
                    <H5>{selectedEmployee?.AgentEmployees?.name}</H5>
                    <P>Name</P>
                  </div>
                </LI>
                <LI>
                  <div className="icon">
                    <Phone />
                  </div>
                  <div>
                    <H5>{selectedEmployee?.AgentEmployees?.mobile}</H5>
                    <P>Mobile</P>
                  </div>
                </LI>
                <LI>
                  <div className="icon">
                    <Activity />
                  </div>
                  <div>
                    <H5>{selectedEmployee?.installmentPointsBalance}</H5>
                    <P>Installment Points Balance</P>
                  </div>
                </LI>
                <LI>
                  <div className="icon">
                    <DollarSign />
                  </div>
                  <div>
                    <H5>{selectedEmployee?.salary}</H5>
                    <P>Salary</P>
                  </div>
                </LI>
                <LI>
                  <div className="icon">
                    <BarChart2 />
                  </div>
                  <div>
                    <H5>{selectedEmployee?.limit}</H5>
                    <P>Limit</P>
                  </div>
                </LI>
                <LI>
                  <div className="icon">
                    <CheckSquare />
                  </div>
                  <div>
                    <H5>
                      {selectedEmployee?.isApproved === true
                        ? "Approved"
                        : "Not Approved"}
                    </H5>
                    <P>Is Approved</P>
                  </div>
                </LI>
                <LI>
                  <div className="icon">
                    <Calendar />
                  </div>
                  <div>
                    <H5>{handleValidDate(selectedEmployee?.startingWorkAt)}</H5>
                    <P>Employment Date</P>
                  </div>
                </LI>
                <LI>
                  <div className="icon">
                    <Calendar />
                  </div>
                  <div>
                    <H5>{handleValidDate(selectedEmployee?.endContractAt)}</H5>
                    <P>Contract Ending</P>
                  </div>
                </LI>
              </UL>
            </CardBody>
          </div>
        </Card>
      </div>
    </Fragment>
  );
};

export default AboutEmployee;
