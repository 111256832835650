import General from "../Components/Widgets/General";
import EmployeeProfile from "../Components/Application/Users/EmployeeProfile";

export const routes = [
  //dashboard
  { path: `/dashboard`, Component: <General /> },
  { path: `/employee/list`, Component: <General /> },
  {
    path: `/employee/details/:id`,
    Component: <EmployeeProfile />,
  },
];
