import React from "react";
import { Suspense, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { auth0 } from "../Config/Config";
import { Auth0Provider } from "@auth0/auth0-react";

import Callback from "../Auth/Callback";
import Loader from "../Layout/Loader";
import { authRoutes } from "./AuthRoutes";
import LayoutRoutes from "../Route/LayoutRoutes";
import Signin from "../Auth/Signin";
import PrivateRoute from "./PrivateRoute";

const Routers = () => {
  const [currentUser, setCurrentUser] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const jwt_token = localStorage.getItem("token");

  return (
    <Auth0Provider
      domain={auth0.domain}
      clientId={auth0.clientId}
      redirectUri={auth0.redirectUri}
    >
      <BrowserRouter basename={"/"}>
        <>
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path={"/"} element={<PrivateRoute />}>
                {currentUser !== null || authenticated || jwt_token ? (
                  <>
                    {/* <Route
                      exact
                      path={`${process.env.PUBLIC_URL}`}
                      element={<Navigate to={`/dashboard`} />}
                    /> */}
                    <Route
                      exact
                      path={`/`}
                      element={<Navigate to={`/dashboard`} />}
                    />
                  </>
                ) : (
                  ""
                )}
                <Route path={`/*`} element={<LayoutRoutes />} />
              </Route>
              <Route
                path={`${process.env.PUBLIC_URL}/callback`}
                render={() => <Callback />}
              />
              <Route exact path={`/login`} element={<Signin />} />
              {authRoutes.map(({ path, Component }, i) => (
                <Route path={path} element={Component} key={i} />
              ))}
            </Routes>
          </Suspense>
        </>
      </BrowserRouter>
    </Auth0Provider>
  );
};

export default Routers;
