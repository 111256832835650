import React, { Fragment, useState } from "react";
import { Label, Button } from "reactstrap";
import { H4, P } from "../../../AbstractElements";
import { Password, SignIn } from "../../../Constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import axios from "axios";
import localforage from "localforage";

const LoginTab = ({ selected }) => {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const history = useNavigate();

  const { register, handleSubmit, reset, getValues } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    const payload = {
      username: getValues("username"),
      password: getValues("password"),
      deviceToken:
        "c4OaffAZS5K7MWytw_GCWG:APA91bE9yGzw9WoEHXbhywRc5bVN9OhCCknk7DssurV_aek38NJIfNl9_X-cFuim_sKL5zRkT7BYMdJlUVv6eLPicftutBY1LIfrwyidJsOIpSsF4vx7yec6tvmHJTTMXum1dz3mMoOa",
      deviceId: "11bafd29-f42b-4def-bc91-7911129e6adc",
    };

    axios({
      url: `${API_URL}agent/login`,
      method: "post",
      data: payload,
    })
      .then(async (res) => {
        reset();
        const token = res?.data?.data?.token;
        await localforage.setItem("token", token);
        localStorage.setItem("token", token);

        history("/dashboard");
        toast("Login Successful");
      })
      .catch((error) => {
        toast("Login failed");
      });
  };

  return (
    <Fragment>
      <form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
        <H4>Sign In</H4>
        <P>{"Enter your number & password to login"}</P>

        <Label className="col-form-label">Username</Label>
        <input
          className="form-control"
          type="number"
          {...register("username", {
            required: "this field is required",
          })}
        />

        <Label className="col-form-label">{Password}</Label>
        <input
          className="form-control"
          type="password"
          {...register("password", {
            required: "this field is required",
          })}
        />

        <div className="form-group mb-0">
          {/* <a className="link" href="#javascript">
            {ForgotPassword}
          </a> */}
          <Button color="primary" onClick={onSubmit} className="mt-5">
            {loading ? "LOADING..." : SignIn}
          </Button>
          <ToastContainer />
        </div>
      </form>
    </Fragment>
  );
};

export default LoginTab;
