import React, { Fragment } from "react";
import { Container, Row } from "reactstrap";
import EmployeeStatusCard from "./EmployeeStatusCard";
import { Breadcrumbs } from "../../../AbstractElements";

const GeneralComponent = () => {
  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Employees"
        parent="Employees"
        title="Employees List"
      />
      <Container fluid={true} className="General-Widget">
        <Row>
          <EmployeeStatusCard />
        </Row>
      </Container>
    </Fragment>
  );
};

export default GeneralComponent;
