import {
  Home,
  Airplay,
  Box,
  FolderPlus,
  Command,
  Cloud,
  BarChart,
  Users,
  ShoppingBag,
  List,
  Mail,
  MessageCircle,
  GitPullRequest,
  Monitor,
  Heart,
  Clock,
  Zap,
  CheckSquare,
  Calendar,
  Image,
  HelpCircle,
  Radio,
  Map,
  Edit,
  Sunrise,
  Package,
  Sliders,
  Layout,
  Server,
  Database,
  Search,
  File,
  Layers,
  UserCheck,
} from "react-feather";

export const MENUITEMS = [
  {
    menutitle: "Dashboard",
    menucontent: "Dashboards,Widgets",
    Items: [
      // {
      //   title: "Companies",
      //   icon: Home,
      //   type: "sub",
      //   active: false,
      //   children: [
      //     {
      //       path: `/dashboard`,
      //       title: "default",
      //       type: "link",
      //     },
      //     {
      //       path: `/dashboard/ecommerce`,
      //       title: "default",
      //       type: "link",
      //     },
      //   ],
      // },
      {
        title: "Agents",
        icon: Airplay,
        type: "sub",
        active: false,
        children: [
          {
            path: `/employee/list`,
            title: "Employees",
            type: "link",
          },
        ],
      },
    ],
  },
];
