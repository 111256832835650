import CreatePwd from "../Components/Pages/Auth/CreatePwd";
import ForgetPwd from "../Components/Pages/Auth/ForgetPwd";
import LoginOne from "../Components/Pages/Auth/LoginOne";
import LoginSweetalert from "../Components/Pages/Auth/LoginSweetalert";
import LoginTooltip from "../Components/Pages/Auth/LoginTooltip";
import LoginTwo from "../Components/Pages/Auth/LoginTwo";
import LoginValidation from "../Components/Pages/Auth/LoginValidation";
import Maintenance from "../Components/Pages/Auth/Maintenance";
import RegisterBgImg from "../Components/Pages/Auth/RegisterBgImg";
import RegisterSimple from "../Components/Pages/Auth/RegisterSimple";
import RegisterVideo from "../Components/Pages/Auth/RegisterVideo";

// import EcommerceTemplates from '../Components/Pages/EmailTemplates/EcommerceTemplates';
import Logins from "../Auth/Signin";
import LoginForm from "../Components/Pages/Auth/LoginForm";

export const authRoutes = [
  { path: `/login`, Component: <Logins /> },
  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/login-simple`,
    Component: <LoginForm />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/login-img`,
    Component: <LoginOne />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/login-bg-img`,
    Component: <LoginTwo />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/login-validation`,
    Component: <LoginValidation />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/login-tooltip`,
    Component: <LoginTooltip />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/login-sweetalert`,
    Component: <LoginSweetalert />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/register-simple`,
    Component: <RegisterSimple />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/register-bg-img`,
    Component: <RegisterBgImg />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/register-video`,
    Component: <RegisterVideo />,
  },

  { path: `/forget-pwd`, Component: <ForgetPwd /> },
  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/create-pwd`,
    Component: <CreatePwd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/maintenance`,
    Component: <Maintenance />,
  },
];
