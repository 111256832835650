import React, { Fragment, useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import LeftbarProfile from "./LeftbarProfile";
import UserProfile from "../../../Bonus-Ui/Tour/UserProfile";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import localforage from "localforage";

const EmployeeProfile = () => {
  const location = useLocation();

  const API_URL = process.env.REACT_APP_API_URL;
  const params = useParams();
  const id = params.id;

  const [selectedEmployee, setSeletedEmployee] = useState(location.state);

  useEffect(() => {
    fetchEmployeeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEmployeeDetails = async () => {
    let token = await localforage.getItem("token");
    const body = {
      token,
      userId: id,
    };

    axios
      .post(`${API_URL}agent/agentUser/details`, body)
      .then((response) => {
        setSeletedEmployee(response?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Employee Profile"
        parent="Agents"
        title="Employee Profile"
      />
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <UserProfile selectedEmployee={selectedEmployee} />
            <LeftbarProfile selectedEmployee={selectedEmployee} />
            <Col xl="9" lg="12" md="7" className="xl-65"></Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};
export default EmployeeProfile;
