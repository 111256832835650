import React, { Fragment, useState } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import { Btn, H5, LI, P, UL } from "../../../../AbstractElements";
import { Briefcase, MapPin, Phone } from "react-feather";

const AboutCompany = ({ colClass, Company }) => {
  const [isShow, setIsShow] = useState(true);
  const toggleClick = () => {
    setIsShow(!isShow);
  };

  return (
    <Fragment>
      <div className={colClass}>
        <Card data-intro="This is your Your detail" className="step4">
          <CardHeader>
            <H5 attrH5={{ className: "p-0" }}>
              <Btn
                attrBtn={{
                  className: "btn btn-link ps-0",
                  color: "transperant",
                  onClick: toggleClick,
                }}
              >
                Company Details
              </Btn>
            </H5>
          </CardHeader>
          <div
            className={`collapse ${isShow ? "show" : ""}`}
            id="collapseicon2"
            aria-labelledby="collapseicon2"
            data-parent="#accordion"
          >
            <CardBody className="post-about">
              <UL>
                <LI>
                  <div className="icon">
                    <Briefcase />
                  </div>{" "}
                  <div>
                    <H5>{Company?.nameEn}</H5>
                    <P>Name English</P>
                  </div>
                </LI>
                <LI>
                  <div className="icon">
                    <Briefcase />
                  </div>
                  <div>
                    <H5>{Company?.nameAr}</H5>
                    <P>Name Arabic</P>
                  </div>
                </LI>
                <LI>
                  <div className="icon">
                    <MapPin />
                  </div>
                  <div>
                    <H5>{Company?.addressEn}</H5>
                    <P>Address English</P>
                  </div>
                </LI>
                <LI>
                  <div className="icon">
                    <MapPin />
                  </div>
                  <div>
                    <H5>{Company?.addressAr}</H5>
                    <P>Address Arabic</P>
                  </div>
                </LI>
                <LI>
                  <div className="icon">
                    <Phone />
                  </div>
                  <div>
                    <H5>{Company?.phone}</H5>
                    <P>Phone</P>
                  </div>
                </LI>
              </UL>
            </CardBody>
          </div>
        </Card>
      </div>
    </Fragment>
  );
};

export default AboutCompany;
