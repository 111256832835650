import React, { Fragment, useState } from "react";
import { Card, CardHeader, CardBody, Input, Label } from "reactstrap";
import { Btn, H5, H6, LI, UL } from "../../../../AbstractElements";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { User } from "react-feather";

const UserTermination = ({ colClass, selectedEmployee }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [isShow, setIsShow] = useState(true);
  const [show, setShow] = useState(false);
  const [settleWay, setSettleWay] = useState("online");

  let token = localStorage.getItem("token");
  const toggleClick = () => {
    setIsShow(!isShow);
  };

  const TerminateUser = () => {
    let body = {
      id: selectedEmployee?.id,
      token: token,
    };
    try {
      axios
        .post(`${API_URL}/agent/agentClient/termination/request`, body)
        .then((res) => {
          window.location.reload();
        });
    } catch (err) {
      console.log(err);
    }
  };

  const PayRequest = () => {
    let body = {
      id: selectedEmployee?.id,
      token: token,
      paymentType: settleWay,
    };
    try {
      axios
        .post(`${API_URL}/agent/agentClient/settleBalance`, body)
        .then((res) => {
          window.location.reload();
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Fragment>
      <div className={colClass}>
        <Card data-intro="This is your Your detail" className="step4">
          <CardHeader>
            <H5 attrH5={{ className: "p-0" }}>
              <Btn
                attrBtn={{
                  className: "btn btn-link ps-0",
                  color: "transperant",
                  onClick: toggleClick,
                }}
              >
                User Termination
              </Btn>
            </H5>
          </CardHeader>
          <div
            className={`collapse ${isShow ? "show" : ""}`}
            id="collapseicon2"
            aria-labelledby="collapseicon2"
            data-parent="#accordion"
          >
            <CardBody className="post-about">
              <UL>
                <LI>
                  <div className="icon">
                    <User />
                  </div>{" "}
                  <div>
                    <div className="checkbox mb-0">
                      <Input
                        id="terminate"
                        type="checkbox"
                        value={selectedEmployee?.isTerminationRequested}
                        onClick={() => {
                          TerminateUser();
                        }}
                        defaultChecked={
                          selectedEmployee?.isTerminationRequested
                        }
                        disabled={selectedEmployee?.isTerminationRequested}
                      />
                      <Label className="text-muted" for="terminate">
                        Terminate <span>User</span>
                      </Label>
                      {selectedEmployee?.isTerminationRequested &&
                      !selectedEmployee?.isTerminationApproved ? (
                        <H6> Waiting For Admin Approval ! </H6>
                      ) : null}
                    </div>
                  </div>
                </LI>
                <LI>
                  <>
                    <button
                      className="btn btn-primary btn btn-default w-25 mx-auto text-center"
                      onClick={handleShow}
                      disabled={
                        selectedEmployee?.isTerminationApproved === false ||
                        selectedEmployee?.balanceSettleRequest === true
                          ? true
                          : false
                      }
                    >
                      Settle Balance
                    </button>

                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Choose Your Settle Way</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Input
                          type="select"
                          name="settleway"
                          onChange={(e) => {
                            setSettleWay(e.target.value);
                          }}
                        >
                          <option value="online">online</option>
                          <option value="offline">offline</option>
                        </Input>
                      </Modal.Body>
                      {settleWay === "offline" ? (
                        <button
                          className="btn btn-primary btn btn-default w-50 mx-auto mb-2"
                          onClick={PayRequest}
                        >
                          Pay Now
                        </button>
                      ) : (
                        <>
                          <div className="mx-auto text-center">
                            <H6>
                              {" "}
                              You Will Pay {selectedEmployee?.holdBalance}{" "}
                              <br /> there is must be a card for payment here
                            </H6>
                          </div>
                          <button
                            onClick={PayRequest}
                            className="btn btn-primary btn btn-default w-50 mx-auto mb-2"
                          >
                            Pay Now
                          </button>
                        </>
                      )}

                      <Modal.Footer>
                        <button
                          className="btn btn-primary btn btn-default"
                          onClick={handleClose}
                        >
                          Close
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </>
                </LI>
              </UL>
            </CardBody>
          </div>
        </Card>
      </div>
    </Fragment>
  );
};

export default UserTermination;
