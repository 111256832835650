import PropTypes from "prop-types";
import React from "react";

import { Card, CardBody, Col, Row } from "reactstrap";
import DataTable from "react-data-table-component";

const Grid = ({
  data,
  columns,
  totalRows,
  handlePageChange,
  handlePerRowsChange,
}) => {
  const customStyles = {
    headCells: {
      style: {},
    },
    table: {
      style: {},
    },
  };

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <DataTable
              columns={columns}
              data={data}
              className="-mt-10"
              customStyles={customStyles}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

Grid.propTypes = {
  data: PropTypes.any,
  columns: PropTypes.any,
  handlePageChange: PropTypes.func,
  handlePerRowsChange: PropTypes.func,
  totalRows: PropTypes.number,
};

export default Grid;
