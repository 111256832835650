import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Button,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import axios from "axios";
import localforage from "localforage";
import { useEffect } from "react";
import readXlsxFile from "read-excel-file";
import moment from "moment";
import Grid from "../../../CommonElements/Grid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EmployeeStatusCard = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const [show, setShow] = useState(false);
  const [employees, setEmployees] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [bulkData, setBulkData] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [extendContractModal, setExtendContractModal] = useState(false);
  const [bulkResponse, setBulkResponse] = useState([]);

  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const { register, handleSubmit, getValues } = useForm({
    mode: "onChange",
  });

  const onSubmit = async () => {
    let tokenRecieved = await localforage.getItem("token");
    let payload = {
      token: tokenRecieved,
      name: getValues("name"),
      salary: getValues("salary"),
      experience: getValues("experience"),
      startingWorkAt: startDate,
      endContractAt:
        endDate === null || endDate === "Invalid date" || endDate === false
          ? null
          : endDate,
      mobile: getValues("mobile"),
      password: getValues("password"),
    };

    if (payload.name === undefined || payload.name.length === 0) {
      toast.error("Name field is required!");
      return;
    }
    if (payload.mobile === undefined || payload.mobile.length === 0) {
      toast.error("Mobile field is required!");
      return;
    }
    if (payload.salary === undefined || payload.salary.length === 0) {
      toast.error("Salary field is required!");
      return;
    }
    if (payload.experience === undefined || payload.experience.length === 0) {
      toast.error("Experience field is required!");
      return;
    }

    if (
      payload.startingWorkAt === undefined ||
      payload.startingWorkAt.length === 0
    ) {
      toast.error("Starting Day field is required!");
      return;
    }
    if (
      payload.endContractAt === undefined ||
      payload.endContractAt.length === 0
    ) {
      toast.error("End Contract field is required!");
      return;
    }

    let alreadyFoundUser = employees.filter((employee) => {
      return (
        employee.name === payload.name && employee.mobile === payload.mobile
      );
    });

    if (alreadyFoundUser.length > 0) {
      toast.error(`${payload.name} Already Exists`);
      return;
    }

    await axios({
      url: `${API_URL}agent/create/agentUser`,
      method: "post",
      data: payload,
    })
      .then(() => {
        // window.location.reload();
        toast("Successfully Added Employee!");
        handleClose();
      })
      .catch((err) => {
        toast.error("Failed to Add new Employee!");
      });
  };

  useEffect(() => {
    fetchEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEmployees = async (pagenumber, limit) => {
    let tokenRecieved = await localforage.getItem("token");
    let payload = {
      token: tokenRecieved,
      // pageNum: pagenumber || 1,
      limit: 500,
    };

    await axios({
      url: `${API_URL}agent/list`,
      method: "post",
      data: payload,
    })
      .then((res) => {
        let sortedData = handleSortFunc(res.data.data.rows);
        setEmployees(sortedData);
        setTotalRows(res?.data.data.count);
      })
      .catch((err) => {
        //console.log("err ", err);
      });
  };

  const handleSortFunc = (employees) => {
    let sortEmployeeData = employees.sort((a, b) => {
      return (
        new Date(a.AgentEmployees.endContractAt).getTime() -
        new Date(b.AgentEmployees.endContractAt).getTime()
      );
    });

    return sortEmployeeData;
  };

  const handlePageChange = (page) => {
    fetchEmployees(page, limit);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchEmployees(page, newPerPage);
  };

  const handleBulkChange = async (event) => {
    let fetchedData = [];
    let fileObj = event.target.files[0];

    await readXlsxFile(fileObj).then((rows) => {
      fetchedData = rows;
      fetchedData.shift();
      setBulkData(fetchedData);
    });
  };

  const onChangeStartDate = (date) => {
    const newDate = moment(new Date(date.target.value)).format("YYYY-MM-DD");
    setStartDate(newDate);
  };

  const onChangeEndDate = (date) => {
    const newDate = moment(new Date(date.target.value)).format("YYYY-MM-DD");
    setEndDate(newDate);
  };

  const handleContractCheckFunc = (date) => {
    let contractDate = new Date(date);
    let todaysDate = new Date();

    if (contractDate.getTime() <= todaysDate.getTime()) {
      return false;
    }

    let dateDifference = Math.abs(
      contractDate.getTime() - todaysDate.getTime()
    );

    let daysLeft = dateDifference / (24 * 60 * 60 * 1000);

    if (daysLeft < 90) {
      return false;
    } else {
      return true;
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => <h5 className="font-size-12 text-dark">{row.name}</h5>,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.mobile}</h5>
      ),
      sortable: true,
    },
    {
      name: "Contract",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">
          {new Date(row.AgentEmployees.endContractAt).getDate()}/
          {new Date(row.AgentEmployees.endContractAt).getMonth() + 1}/
          {new Date(row.AgentEmployees.endContractAt).getFullYear()}{" "}
          {!handleContractCheckFunc(row.AgentEmployees.endContractAt) && (
            <button onClick={() => setExtendContractModal(true)}>Manage</button>
          )}
        </h5>
      ),
      sortable: true,
    },
    {
      name: "Details",
      cell: (row) => (
        <Link className="text-success" to={`/employee/details/${row.id}`}>
          <button className="btn btn-primary">View</button>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const viewExcelData = () => {
    setShowPreview(true);
  };

  const onSubmitBulkVerify = async () => {
    let tokenRecieved = await localforage.getItem("token");
    let totalEmployees = [];

    for (let data of bulkData) {
      let createStartDate = {
        day:
          new Date(data[2]).getDate() < 10
            ? `0${new Date(data[2]).getDate()}`
            : new Date(data[2]).getDate(),
        month:
          new Date(data[2]).getMonth() + 1 < 10
            ? `0${new Date(data[2]).getMonth() + 1}`
            : new Date(data[2]).getMonth() + 1,
        year: new Date(data[2]).getFullYear(),
      };
      let createEndDate = {
        day:
          new Date(data[3]).getDate() < 10
            ? `0${new Date(data[3]).getDate()}`
            : new Date(data[3]).getDate(),
        month:
          new Date(data[3]).getMonth() + 1 < 10
            ? `0${new Date(data[3]).getMonth() + 1}`
            : new Date(data[3]).getMonth() + 1,
        year: new Date(data[3]).getFullYear(),
      };

      let singleEmployee = {
        name: data[0].toString(),
        salary: data[4].toString(),
        endContractAt:
          createEndDate.year +
          "-" +
          createEndDate.month +
          "-" +
          createEndDate.day,
        startingWorkAt:
          createStartDate.year +
          "-" +
          createStartDate.month +
          "-" +
          createStartDate.day,
        mobile: data[1].toString(),
        password: data[5].toString(),
        experience: data[6].toString(),
      };

      totalEmployees.push(singleEmployee);
    }

    if (totalEmployees.length > 0) {
      let payload = {
        token: tokenRecieved,
        agentEmployees: totalEmployees,
      };

      await axios({
        url: `${API_URL}agent/agentClient/bulkVerify`,
        method: "post",
        data: payload,
      })
        .then((res) => {
          let bulkResponse = [];
          for (let bulkResponses of res.data.data) {
            let obj = {
              reason: bulkResponses.reason,
              status: bulkResponses.status,
            };
            bulkResponse.push(obj);
          }
          setBulkResponse([...bulkResponse]);
        })
        .catch((err) => {
          console.log("err ", err);
        });
    }
  };

  const onSubmitBulkFunc = async () => {
    let tokenRecieved = await localforage.getItem("token");

    for (let data of bulkData) {
      let createStartDate = {
        day:
          new Date(data[2]).getDate() < 10
            ? `0${new Date(data[2]).getDate()}`
            : new Date(data[2]).getDate(),
        month:
          new Date(data[2]).getMonth() + 1 < 10
            ? `0${new Date(data[2]).getMonth() + 1}`
            : new Date(data[2]).getMonth() + 1,
        year: new Date(data[2]).getFullYear(),
      };
      let createEndDate = {
        day:
          new Date(data[3]).getDate() < 10
            ? `0${new Date(data[3]).getDate()}`
            : new Date(data[3]).getDate(),
        month:
          new Date(data[3]).getMonth() + 1 < 10
            ? `0${new Date(data[3]).getMonth() + 1}`
            : new Date(data[3]).getMonth() + 1,
        year: new Date(data[3]).getFullYear(),
      };

      let payload = {
        token: tokenRecieved,
        name: data[0].toString(),
        salary: data[4].toString(),
        endContractAt:
          createEndDate.year +
          "-" +
          createEndDate.month +
          "-" +
          createEndDate.day,
        startingWorkAt:
          createStartDate.year +
          "-" +
          createStartDate.month +
          "-" +
          createStartDate.day,
        mobile: data[1].toString(),
        password: data[5].toString(),
        experience: data[6].toString(),
      };

      await axios({
        url: `${API_URL}agent/create/agentUser`,
        method: "post",
        data: payload,
      })
        .then((res) => {
          setEmployees([...employees, res.data.data]);
        })
        .catch((err) => {
          console.log("err ", err);
        });
    }

    setShowPreview(false);
    window.location.reload();
  };

  return (
    <Col xl="12" className="xl-100 box-col-12">
      <Card className="employee-status">
        <CardHeader className="pb-0 d-flex justify-content-between align-items-center">
          <button
            className="btn btn-primary btn btn-default"
            onClick={handleShow}
          >
            Add Employee
          </button>
          <input type="file" onChange={handleBulkChange} />
          <Button disabled={bulkData.length < 1} onClick={viewExcelData}>
            View Excel Data
          </Button>
        </CardHeader>
        <CardBody>
          {employees?.length !== 0 ? (
            <>
              <Grid
                data={employees}
                columns={columns}
                totalRows={totalRows}
                limit={limit}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
              />
            </>
          ) : (
            ``
          )}
        </CardBody>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Create Employee</Modal.Title>
          </Modal.Header>
          <form onSubmit={handleSubmit(onSubmit)} className="p-3">
            <Label className="col-form-label">Name</Label>
            <input
              className="form-control"
              type="text"
              {...register("name", {
                required: "Name is required",
              })}
            />

            <Label className="col-form-label">Mobile</Label>
            <input
              className="form-control"
              type="text"
              {...register("mobile", {
                required: "Mobile is required",
              })}
            />

            <Label className="col-form-label">Password</Label>
            <input
              type="password"
              className="form-control"
              {...register("password", {
                required: "Password is required",
              })}
            />

            <Label className="col-form-label">Starting Work At:</Label>
            <Input
              className="form-control digits"
              type="date"
              name="startingWorkAt"
              onChange={(e) => onChangeStartDate(e)}
            />

            <Label className="col-form-label">End Contract at</Label>
            <Input
              className="form-control digits"
              type="date"
              name="endContractAt"
              onChange={(e) => onChangeEndDate(e)}
            />

            <Label className="col-form-label">Salary</Label>
            <input
              className="form-control"
              type="text"
              {...register("salary", {
                required: "Salary is required",
              })}
            />

            <Label className="col-form-label">Experience</Label>
            <input
              className="form-control"
              type="text"
              {...register("experience", {
                required: "Experience is required",
              })}
            />
          </form>
          <Modal.Footer>
            <button
              className="btn btn-primary btn btn-default"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              className="btn btn-primary btn btn-default"
              onClick={onSubmit}
            >
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={extendContractModal}
          onHide={() => setExtendContractModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create Employee</Modal.Title>
          </Modal.Header>
          Contract Modal
          <Modal.Footer>
            <button
              className="btn btn-primary btn btn-default"
              onClick={() => setExtendContractModal(false)}
            >
              Close
            </button>
            <button
              className="btn btn-primary btn btn-default"
              onClick={onSubmit}
            >
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          // style={{ maxWidth: "100%" }}
          show={showPreview}
          onHide={() => setShowPreview(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Employee List</Modal.Title>
          </Modal.Header>
          <DisplayUsers
            setBulkData={setBulkData}
            setBulkResponse={setBulkResponse}
            bulkResponse={bulkResponse}
            bulkData={bulkData}
          />
          <Modal.Footer>
            <button
              className="btn btn-primary btn btn-default"
              onClick={() => setShowPreview(false)}
            >
              Close
            </button>
            <button
              className="btn btn-primary btn btn-default"
              onClick={onSubmitBulkVerify}
            >
              Verify
            </button>
            <button
              className="btn btn-primary btn btn-default"
              onClick={onSubmitBulkFunc}
            >
              Upload
            </button>
          </Modal.Footer>
        </Modal>
      </Card>
    </Col>
  );
};

const DisplayUsers = ({
  bulkData,
  setBulkData,
  bulkResponse,
  setBulkResponse,
}) => {
  const handleDeleteFunc = (idx) => {
    bulkData.splice(idx, 1);
    setBulkData([...bulkData]);
  };

  const handleChangeFunction = (e, idx, key) => {
    bulkData[idx][key] = e.target.value;
    setBulkData([...bulkData]);
  };

  return (
    <div>
      <table>
        <tr>
          <th>Name</th>
          <th>Mobile</th>
          <th>Starting Work At</th>
          <th>End Contract At</th>
          <th>Salary</th>
          <th>Password</th>
          <th>Experience</th>
          <th>Delete</th>
          <th>Reason</th>
          <th>Status</th>
        </tr>
        {bulkData.map((singleEmployee, idx) => (
          <tr>
            <td>
              <input
                onChange={(e) => handleChangeFunction(e, idx, "0")}
                style={{ width: 100, marginRight: 8 }}
                value={singleEmployee[0]}
              />
            </td>
            <td>
              <input
                onChange={(e) => handleChangeFunction(e, idx, "1")}
                style={{ width: 100, marginRight: 8 }}
                value={singleEmployee[1]}
              />
            </td>
            <td>
              <input
                onChange={(e) => handleChangeFunction(e, idx, "2")}
                style={{ width: 100, marginRight: 8 }}
                value={
                  new Date(singleEmployee[2]).getDate() +
                  "/" +
                  new Date(singleEmployee[2]).getMonth() +
                  "/" +
                  new Date(singleEmployee[2]).getFullYear()
                }
              />
            </td>
            <td>
              <input
                onChange={(e) => handleChangeFunction(e, idx, "3")}
                style={{ width: 100, marginRight: 8 }}
                value={
                  new Date(singleEmployee[3]).getDate() +
                  "/" +
                  new Date(singleEmployee[3]).getMonth() +
                  "/" +
                  new Date(singleEmployee[3]).getFullYear()
                }
              />
            </td>
            <td>
              <input
                onChange={(e) => handleChangeFunction(e, idx, "4")}
                style={{ width: 100, marginRight: 8 }}
                value={singleEmployee[4]}
              />
            </td>
            <td>
              <input
                onChange={(e) => handleChangeFunction(e, idx, "5")}
                style={{ width: 100, marginRight: 8 }}
                value={singleEmployee[5]}
              />
            </td>
            <td>
              <input
                onChange={(e) => handleChangeFunction(e, idx, "6")}
                style={{ width: 100, marginRight: 8 }}
                value={singleEmployee[6]}
              />
            </td>
            <td>
              <button onClick={() => handleDeleteFunc(idx)}>Delete</button>
            </td>
            <td>
              <div style={{ color: "white" }}>{bulkResponse[idx]?.reason}</div>
            </td>
            <td>
              <div style={{ color: "white" }}>{bulkResponse[idx]?.status}</div>
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default EmployeeStatusCard;
