import React, { Fragment } from "react";
import { Col, Card } from "reactstrap";
import { Link } from "react-router-dom";
import UserSeven from "../../../assets/images/user/7.jpg";
import { H4, H6, Image, LI, UL } from "../../../AbstractElements";

const UserProfile = ({ selectedEmployee }) => {
  return (
    <Fragment>
      <Col sm="12">
        <Card
          className="profile-header bg-size"
          style={{
            backgroundColor: `#111727`,
          }}
        >
          <div className="profile-img-wrrap">
            <Image
              attrImage={{
                className: "img-fluid bg-img-cover",
                src: `${require("../../../assets/images/user-profile/bg-profile.jpg")}`,
                alt: "",
              }}
            />
          </div>
          <div className="userpro-box">
            <div className="img-wrraper">
              <div className="avatar">
                <Image
                  attrImage={{
                    className: "step1",
                    alt: "",
                    src: `${UserSeven}`,
                  }}
                />
              </div>
              {/* <div className="icon-wrapper">
                <i
                  className="icofont icofont-pencil-alt-5 step2"
                  data-intro="Change Profile image here"
                ></i>
              </div> */}
            </div>
            <div className="user-designation">
              <div className="title m-0">
                <Link to={`${process.env.PUBLIC_URL}/app/users/userprofile`}>
                  <H4>{selectedEmployee?.AgentEmployees?.name}</H4>
                </Link>
                <H6>{selectedEmployee?.AgentEmployees?.mobile}</H6>
              </div>

              <div className="follow">
                <UL attrUL={{ className: "follow-list flex-row simple-list" }}>
                  <LI>
                    <div className="follow-num counter">
                      {selectedEmployee?.salary}
                    </div>
                    <span>Salary</span>
                  </LI>
                  <LI>
                    <div className="follow-num counter">
                      {selectedEmployee?.limit}
                    </div>
                    <span>Limit</span>
                  </LI>
                </UL>
              </div>
            </div>
          </div>
        </Card>
      </Col>
    </Fragment>
  );
};

export default UserProfile;
