import React, { Fragment } from "react";
import { Row, Col } from "reactstrap";
import AboutCompany from "../../../Bonus-Ui/Tour/Leftbar/AboutCompany";
import AboutEmployee from "../../../Bonus-Ui/Tour/Leftbar/AboutEmployee";
import UserTermination from "../../../Bonus-Ui/Tour/Leftbar/userTermination";

const LeftbarProfile = ({ selectedEmployee }) => {
  return (
    <Fragment>
      <Col xl="12" lg="12" md="12" className="">
        <div className="default-according style-1 faq-accordion job-accordion">
          <Row>
            <AboutEmployee
              colClass="col-xl-12"
              selectedEmployee={selectedEmployee}
            />
            <AboutCompany
              colClass="col-xl-12"
              Company={selectedEmployee?.Company}
            />
            <UserTermination
              colClass="col-xl-12"
              selectedEmployee={selectedEmployee}
            />
          </Row>
        </div>
      </Col>
    </Fragment>
  );
};

export default LeftbarProfile;
